import React from 'react';
import Layout from '../components/Layout';
import Container from '../components/Container';

const MunicipalityPage = () => (
  <Layout>
    <Container>
      <h1>För kommuner</h1>
      <p>
        Eftersom vi forskar om skolval har vi besökt många kommuner. Vi har sett
        hur kommuner brottas med diverse frågor och problem som är relaterade
        till skolvalet. Hur gör man det enkelt för vårdnadshavare att ange
        önskemål om skolplacering? Hur säkerställer man att ens system faktiskt
        respekterar vårdnadshavarnas önskemål? Hur kan man enklast och mest
        effektivt tillämpa sitt eget regelverk? Hur kan man utforma ett
        regelverk som garanterar närhetsprincipen och som samtidigt bidrar till
        en ökad elevblandning? Hur kan man integrera fristående skolor i
        systemet?
      </p>
      <p>
        Att ovanstående frågor är svåra att besvara är inte konstigt. Man måste
        bland annat förhålla sig till en komplicerad forskningslitteratur, en
        otydlig lagstiftning och vårdnadshavare med starka viljor. Att
        förvaltningsdomstolar har olika tolkning av lagtexten underlättar inte
        situationen. Vi bestämde oss därför för att bygga en plattform som
        kommuner kan använda för att administrera och utveckla sina skolval.
      </p>
      <p>
        Denna plattform har nu varit i bruk sedan skolvalet till läsåret
        2018/2019. Första året genomfördes en pilot i en kommun med stor
        framgång. I år har plattformen, på olika sätt, använts för att genomföra
        skolvalen i sju olika kommuner. Målsättningen är en gemensam plattform
        för hela Sverige där alla skolhuvudmän (kommuner och fristående skolor)
        enkelt och smidigt ska kunna sköta sina skolval.
      </p>
      <p>Vår plattform består av fyra moduler:</p>
      <ol>
        <li>
          En valmodul där vårdnadshavare enkelt kan logga in med BankID och
          registrera sina önskemål om skola. Denna modul är byggd enligt samma
          principer som en modern app. Varje steg är tydligt avgränsat vilket
          gör det enkelt för vårdnadshavare att navigera genom tjänsten. Detta,
          i kombination med att modulen är responsiv, gör det enkelt för
          vårdnadshavare att registrera sina önskemål i mobilen. Vidare
          uppfyller modulen relevanta tillgänglighetsnormer och är, för
          tillfället, översatt till nio olika språk.{' '}
        </li>
        <li>
          En placeringsmodul som givet kommunens regler, skolornas kapaciteter
          och vårdnadshavarnas önskemål placerar elever vid skolor. Placeringen
          sker med hjälp av algoritmer som är konstruerade av världsledande
          forskare på området men som är anpassade för svenska förhållanden och
          lagstiftning. Algoritmerna placerar elever i enlighet med kommunens
          regelverk. De elever som placeras på en översökt skola kommer att ha
          ”bättre rätt” till den skolan än samtliga elever som önskar bli
          antagna vid skolan men inte har blivit placerade där. Vidare är
          algoritmerna konstruerade så att vårdnadshavare inte kan ”lura”
          systemet. Detta innebär att det bästa en vårdnadshavare kan göra är
          att lista skolorna sanningsenligt, alltså i den ordning man föredrar
          dem. Denna egenskap gör det enkelt för vårdnadshavare att önska skolor
          eftersom de inte behöver fundera på hur andra vårdnadshavare agerar.
          Egenskapen medför också att de data som genereras i skolvalet blir
          tillförlitliga vilket innebär att man som kommun kan använda denna
          data för utvärdering och analys. Slutligen är algoritmerna, för varje
          givet regelverk, effektiva. Detta innebär att de, i den utsträckning
          det går, placerar elever vid deras önskade skolor. Algoritmerna kan
          hantera ett flertal olika uppsättningar av förtursregler vilket
          innebär att kommuner inte behöver begränsa sig till olika typer av
          relativa närhetsmått.{' '}
        </li>
        <li>
          En kartmodul som, baserat på kommunens adresspunktlager och vägnät,
          räknar ut de avstånd som behövs för att avgöra vilka elever som har
          förtur till vilka skolor. Modulen bygger på GIS-teknik. För kommuner
          som inte har tillgång till filer som beskriver vägnätet eller likande
          planerar vi att bygga en alternativ lösning där vår plattform kopplar
          upp mot ett kartverktyg online.
        </li>
        <li>
          En administrationsmodul där man enkelt kan sätta upp, följa och
          administrera sitt skolval. I denna modul finns det även möjlighet att
          kommunicera med vårdnadshavare genom brev, sms och e-post. Inom
          modulen finns också möjlighet för ombud att logga in och hjälpa
          vårdnadshavare som, av olika anledningar, har problem att registrera
          sina val.
        </li>
      </ol>
      <p>
        Systemet kan fullt ut integrera fristående skolor i antagningen. På
        detta sätt kan man undvika problem med dubbla placeringar som upptäcks
        sent och därmed slippa hålla markant överkapacitet under en lång tid.
      </p>
      <p>
        Man kan köpa modulerna separat men vi rekommenderar att man köper hela
        paketet då vi har märkt att processen blir markant mycket smidigare för
        både oss och de kommuner som använder vår plattform. Vår plattform är en
        molnlösning vilket innebär att vi kan hålla låga priser. För att det ska
        vara rättvist mellan små och stora kommuner är vår prissättning baserad
        på antal invånare i kommunen. Extra kostnader tillkommer endast för
        sådant som kostar oss pengar, exempelvis postförsändelser och sms.
      </p>
      <p>
        Om ni är intresserade av vår plattform eller bara vill diskutera ert
        skolval är ni välkomna att höra av er till oss. Lättast når ni oss på
        hello@mittskolval.se. Vi ger gärna referenser till alla de kommuner vi
        arbetar med.
      </p>
    </Container>
  </Layout>
);

export default MunicipalityPage;
